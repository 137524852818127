@import url('https://fonts.googleapis.com/css2?family=Saira+Condensed:wght@300&display=swap');

body {
  text-align: center;
  font-size: 100%;
  min-height: 300vh;
  font-family: 'Saira Condensed', sans-serif;
  color: #AFDDE5; /* Changed text color */
  background-color: #003135; /* Changed background color */
}

.h1 {
  min-height: 25vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2.5em;
  color: #AFDDE5; /* Changed heading color */
}

.intro-container {
  padding: 0 20px; /* Adjust padding as needed */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.intro {
  font-size: 2.0rem;
  color: #AFDDE5; /* Changed intro text color */
}

.intro-small {
  font-size: 1.5rem;
  color: #AFDDE5; /* Changed smaller intro text color */
}

.image {
  border-radius: 8px;
  padding: 15px;
  width: 100%;
  height: 100%;
  display: table-column;
  background-color: #024950; /* Changed image background color */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.image:hover {
  opacity: 0.5; /* Kept the same hover effect */
}

.container {
  font-size: 1.75em;
  text-align: center;
  float: left;
  width: 40%;
  height: 50%;
  padding-left: 25px;
  padding-right: 25px;
  color: #AFDDE5; /* Changed container text color */
}

.container > a {
  color: #0FA4AF;
}

.description {
  font-size: 1.50em;
  align-items: left;
  display: inline-block;
  justify-content: left;
  text-align: left;
  width: 40%;
  padding-left: 25px;
  padding-right: 25px;
  color: #AFDDE5; /* Changed description text color */
}

.description > a {
  color: #0FA4AF
}

.nav-items > a {
  text-decoration: none;
  color: #AFDDE5; /* Changed navigation link color */
}

.nav-container {
  border-bottom: 5px solid #0FA4AF; /* Changed nav border color */
  overflow: visible;
  display: flex;
  align-items: center;
  padding-left: 50px; /* Adjust as needed */
}

.nav-items {
  font-size: 2.25rem;
  text-align: right;
  padding-right: 100px;
  flex-grow: 1;
}

.nav-name {
  font-size: 2.25rem;
  color: #AFDDE5; /* Changed nav name color */
  line-height: 3rem; /* Adjust as needed */
}

.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown a {
  color: #AFDDE5; /* Changed dropdown link color */
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #ffffff; /* Light background for dropdown */
  min-width: 200px;
  z-index: 1;
}

.dropdown-content a {
  color: #003135; /* Changed dropdown text color */
  padding: 8px 12px;
  text-decoration: none;
  display: block;
  font-size: 1.25rem;
}

.dropdown-content a:hover {
  background-color: #f1f1f1; /* Light gray on hover */
}

.dropdown:hover .dropdown-content {
  display: block;
}

.about-details {
  display: flex;
  align-items: center; /* Align items vertically to the center */
}

.left-section {
  display: flex;
  flex-direction: column;
  flex-grow: 1; /* Allow the left section to grow to fill available space */
  align-items: center;
}

.about-details .intro {
  font-size: 2.0rem;
  color: #AFDDE5; /* Changed intro color in about section */
}

.about-details .intro-text {
  font-size: 2.0rem; /* Adjust font size as needed */
  color: #AFDDE5; /* Changed intro text color */
  padding-bottom: 20px; /* Retaining original padding */
}

.about-details .image {
  border-radius: 8px;
  padding: 15px;
  width: 50%; /* Retaining original image width */
  height: auto; /* Maintain aspect ratio */
  background-color: #024950; /* Match image background color */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.p {
  font-size: 0.875em;
  color: #AFDDE5; /* Changed paragraph text color */
}

hr {
  width: 80%;
  border: 1px solid #0FA4AF; /* Changed horizontal rule color */
}

#eval {
  height: 70%;
  width: 70%;
  margin-left: 50px;
}

#me {
  max-width: 100%; /* Adjust the maximum width as needed */
  width: 80%; /* Ensure the image fills its container */
  margin-top: 50px;
  display: block; /* Ensure the image aligns correctly with text */
  margin-bottom: 20px; /* Add space below the image */
}

/* Mobile Styles */
@media only screen and (max-width: 600px) {
  body {
    overflow-x: hidden; /* Prevent horizontal scrolling */
  }

  .nav-container {
    flex-direction: column;
    padding: 10px;
    align-items: center; /* Center items vertically */
  }

  .nav-items {
    text-align: center;
    padding: 0;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center items horizontally */
  }

  .nav-items > a {
    margin: 0 10px; /* Adjust spacing between items */
  }

  .nav-name {
    line-height: 1rem; /* Adjust as needed */
  }

  .dropdown {
    display: block;
    margin: 0 10px;
    text-align: center;
  }

  .dropdown-content {
    position: static;
    display: none;
    background-color: #f9f9f9; /* Light gray for dropdown */
    min-width: 160px;
    z-index: 1;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .dropdown-content a {
    display: block;
    padding: 8px;
  }

  .container,
  .description {
    width: 90%;
    margin: 0 auto;
    overflow-wrap: break-word; /* Allow text to wrap */
  }

  .about-details {
    flex-direction: column; /* Stack elements vertically on mobile */
    align-items: flex-start; /* Align items to the start (left) on mobile */
  }

  .about-details .intro {
    margin-right: 0; /* Reset margin for mobile */
  }

  .left-section {
    width: 100%; /* Ensure full width on mobile */
  }

  .about-details .image {
    width: 100%; /* Full width on mobile */
    height: 75%;
    margin-bottom: 20px; /* Add space between image and text on mobile */
  }

  .intro-small {
    padding: 0;
    padding-right: 10px;
    width: 100%; /* Expand intro-small to full width */
    box-sizing: border-box; /* Ensure padding doesn't increase width */
  }

  .intro-container {
    padding: 0 10px; /* Adjust padding for smaller screens */
  }
}

/* Desktop Styles */
@media only screen and (min-width: 601px) {
  .container,
  .description {
    width: 40%;
    float: left;
  }

  .description {
    padding-left: 25px;
    padding-right: 25px;
  }

  .container {
    padding-left: 25px;
    padding-right: 25px;
  }

  .intro,
  .intro-small {
    width: 50%;
    padding-left: 100px;
    padding-right: 100px;
  }
}
